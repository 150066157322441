
import Vue from 'vue';

export default Vue.extend({
    name: 'PageHeader',

    computed: {
        hasCenterAndMenuSlot(): boolean {
            return this.hasMenuSlot && this.hasCenterSlot;
        },
        hasCenterSlot(): boolean {
            return !!this.$slots.centerContent;
        },
        hasMenuSlot(): boolean {
            return !!this.$slots.menu;
        },
        hasTitleSlot(): boolean {
            return !!this.$slots.title;
        },
        hasSubTitleSlot(): boolean {
            return !!this.$slots.subTitle;
        }
    }
});
