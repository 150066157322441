<template>
    <div id="PublicInvoicableView">
        <view-layout v-if="!loading">
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                    <template #menu>
                        <slot name="menu" />
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-row v-if="invoicable">
                    <v-col cols="12">
                        <PDFViewer :src="invoicable.url" :file-name="`Document`" />
                    </v-col>
                </v-row>
                <v-row v-if="errorMessage">
                    <v-col cols="12">
                        <h3 class="text-center">{{ errorMessage }}</h3>
                    </v-col>
                </v-row>
            </template>
        </view-layout>
        <loader v-if="loading" />
    </div>
</template>

<script>
import Loader from '@/components/Loader';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
import PDFViewer from '@/components/PDFViewer.vue';

export default {
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        PDFViewer
    },
    props: ['invoicable', 'loading', 'errorMessage']
};
</script>
